<template>
	<v-container class="fill-height" fluid>
		<v-row align="center" justify="center">
			<v-col cols="10" sm="8" md="4" lg="3" xl="2">
				<v-card outlined>
					<v-toolbar	color="primary"	dark flat dense>
						<v-toolbar-title>SP Planning</v-toolbar-title>
					</v-toolbar>
					<v-card-text class="py-2">
						<v-form>
							<v-text-field label="Matricule"	prepend-icon="mdi-account" type="number" v-model.trim="$v.formulaire_connexion.matricule.$model" :error-messages="erreurMatricule"></v-text-field>
							<v-text-field label="Mot de passe" prepend-icon="mdi-lock" type="password" v-model.trim="$v.formulaire_connexion.password.$model" :error-messages="erreurPassword" @keyup.enter="authenticate"></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<div class="flex-grow-1"></div>
						<v-btn depressed color="primary" @click="authenticate" :disabled="this.$v.formulaire_connexion.$invalid" :loading="chargement_connexion">Go</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
    </v-container>
  
</template>
<script>
	import axios from 'axios'
	import { required } from 'vuelidate/lib/validators'
  	export default {
		data() {
			return {
				formulaire_connexion: {
					matricule: null,
					password: null
				},
				chargement_connexion: false,
				notificationsActivees: false,
        		notificationSupportees: false,
        		serviceWorkerRegistation: null,
        		subscription: null,		
			}
		},
		computed: {
			erreurMatricule() {
				const errors = []
				if (!this.$v.formulaire_connexion.matricule.$dirty) return errors
				!this.$v.formulaire_connexion.matricule.required && errors.push('Le matricule est obligatoire')
                return errors
			},
			erreurPassword() {
				const errors = []
				if (!this.$v.formulaire_connexion.password.$dirty) return errors
				!this.$v.formulaire_connexion.password.required && errors.push('Le mot de passe est obligatoire')
                return errors
			},
			
		},
		methods: {
			acces_autorise(permissions_requises, acces_admin = true) {
                // on vérifie si l'utilisateur a les droits
                //  "permissions_requises" est un tableau de permissions.
                //  return true si l'utilisateur possede l'une des permissions du tableau

                // "acces_admin"  donne ou non l'autorisation à l'admin
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
					const permissions_utilisateur = this.$store.getters.membreCourant.permissions
					const permissions_centre = this.$store.getters.membreCourant.centre.parametres

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin && acces_admin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) && permissions_centre.includes(permission.name)) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) && permissions_centre.includes(permission.name)) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			ajoutMajSouscription(sub) {
				//  enregistrement de la souscription sur le server
				axios.post('api/user/inscription_notification', { subscription: this.subscription })
					.then( (reponse) => {
						//this.snackbar('success',reponse.data.resultat);
						//console.log('Inscription aux notifications mise à jour: ', reponse.data.inscription)
						this.notificationsActivees = true
						//this.$store.dispatch('affichNotification')
					})
					.catch( (error) => {
						//this.snackbar('error', 'Erreur lors de l\'autorisation');
						console.log('Erreur lors de l\'insciption aux notification', error)
						this.notificationsActivees = false
					})
																
			},
			authenticate() {
				 //  on vérifie que le formulaire est valide
                if( !this.$v.formulaire_connexion.$invalid) {
					this.chargement_connexion = true
					this.$store.dispatch('login')
					this.$store.dispatch('loginReq', this.formulaire_connexion)
						.then((reponse) => {
							this.$store.dispatch('getMessagesNonLus', this.$store.getters.membreCourant.id_user)
							
							if(this.acces_autorise(['superAdmin'])) this.$store.commit('connexionOnline')
							
							//	Activation des notifications
							// On vérifie si l'utilisateur a déjà activé les notifications.
							// Si oui, on met à true le flag 'notificationsActivees'
							this.findSubscription()
								.then(sub => {
									if (sub === null) {
										//console.log('Aucune souscription active trouvé sur le client', sub);
										this.notificationsActivees = false

										//  on active les notifications
										Notification.requestPermission()
											.then(result => {
												if (result === 'granted') {
													this.createSubscription()
													//  une fois la souscription créée côté client...
														.then( sub => {
															this.subscription = sub
															this.ajoutMajSouscription(sub)
														})
											}
										})
									} 
									else {
										//console.log('Souscription active trouvée', sub);
										this.notificationsActivees = true
										this.subscription = sub
										//	on la met à jour dans la bdd au cas où elle n'y serait plus
										this.ajoutMajSouscription(sub)
									}
							})

							//	navigation sur la page par défaut selon les permissions
							if(this.acces_autorise(['acces planning spv'])) {
								this.$router.push('/planning_spv')
							}

							//	sinon la page d'accueil dépenda du role
							else {
								//	navigation sur la page par défaut selon le role de l'utilisateur connecté
								const membre = this.$store.getters.membreCourant
								switch (membre.roles[0].id) {
									case 1:	// chef de centre
										this.$router.push('/plannification_gardes')
										break;
								// 	case 2:	// super admin
								// 		this.$router.push('/planning_spv')
								// 		break;
									case 3:	// stationnaire
										this.$router.push('/dispos_centre')
										break;
								// 	case 4:	// utilisateur
								// 		this.$router.push('/planning_spv')
								// 		break;
								// 	case 5:	// chef d'équipe
								// 		this.$router.push('/planning_spv')
								// 		break;
								// 	case 6:	// adjoint équipe
								// 		this.$router.push('/planning_spv')
								// 		break;
									case 7:	// service liste
										this.$router.push('/dispos_centre')
										break;
									case 8:	// encadrement
										this.$router.push('/dispos_centre')
										break;
									default:
										this.$router.push('/')
										break;
								}
							}
							
							//this.$router.push({path: '/'})
						})
						.catch((error) =>{
							this.snackbar('error', error.response.data.error)
						})
						.then(()=> {
							this.chargement_connexion = false
						})
				}
			},
			createSubscription() {
				//console.log('ask for active service worker registration');
				//console.log(this.serviceWorkerRegistation)
				if (this.serviceWorkerRegistation === null) {
					return navigator.serviceWorker.ready // returns a Promise, the active SW registration
					.then(swreg => {
							this.serviceWorkerRegistation = swreg
							return this.subscribe(this.serviceWorkerRegistation)
						})
					} 
				else {
					return this.subscribe(this.serviceWorkerRegistation)
				}
			},
			findSubscription() {
				return navigator.serviceWorker.ready
					.then(swreg => {
						this.serviceWorkerRegistation = swreg
						return this.getSubscription(this.serviceWorkerRegistation)
				})
			},
			getSubscription(swreg) {
				return swreg.pushManager.getSubscription()
			},	
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			subscribe(swreg) {
				//console.log('create new subscription for this browser on this device');
				// create new subscription for this browser on this device
				const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY
				const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
				// return the subscription promise, we chain another then where we can send it to the server
				return swreg.pushManager.subscribe({
					userVisibleOnly: true,
					// This is for security. On the backend, we need to do something with the VAPID_PRIVATE_KEY
					// that you can find in .env to make this work in the end
					applicationServerKey: convertedVapidPublicKey
				})
			},
			urlBase64ToUint8Array(base64String) {
				const padding = '='.repeat((4 - base64String.length % 4) % 4);
				const base64 = (base64String + padding)
					.replace(/\-/g, '+')
					.replace(/_/g, '/');
				const rawData = window.atob(base64);
				let outputArray = new Uint8Array(rawData.length);
				for (let i = 0; i < rawData.length; ++i) {
					outputArray[i] = rawData.charCodeAt(i);
				}
				return outputArray;
			},
			
		},
		validations: {
			formulaire_connexion: {
				matricule: {
					required
				},
				password: {
					required
				}
			}
		}
  	}
</script>

<style>

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

</style>